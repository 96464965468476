<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!--
 * @FilePath: /copyright/src/views/Complete.vue
 * @Description: 添加文件描述~~~
 * @Author: jiangshengkai@baidu.com
 * @Date: 2022-10-12 15:49:15
 * @LastEditors: jiangshengkai@baidu.com
 * @LastEditTime: 2023-02-07 17:48:41
-->
<template>
    <div class="complete">
        <p class="title">
            <i></i>
            反馈完成
        </p>
        <p class="center">
            我们会尽快审核处理，并通过百度帐号下发给您，您也可以在
            <router-link to="/record">个人中心</router-link>
            查看反馈进度
        </p>
        <el-button type="primary" @click="success">完成</el-button>
    </div>
</template>

<script>
export default {
    methods: {
        success() {
            this.$router.push({
                path: '/',
            });
        },
    },
    mounted() {
        sessionStorage.setItem('active', 0);
    },
};
</script>

<style scoped lang='less'>
.el-button--primary {
    background-color: #2d63e0;
}
.el-button--primary:hover {
    background: #326efa;
}
.el-button--primary:active {
    background: #2858c7;
}
.complete {
    width: 1200px;
    height: 730px;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    padding-top: 200px;
    background: #fff;
    border-radius: 4px;
    .title {
        font-weight: bold;
        font-size: 24px;
        color: #1e2024;
        i {
            display: inline-block;
            width: 26px;
            height: 26px;
            background: url(../assets/img/correct.png) no-repeat;
            background-size: 100% 100%;
            vertical-align: top;
            position: relative;
            top: 5px;
        }
    }
    .center {
        width: 270px;
        margin: 20px auto;
        font-size: 14px;
        color: #767981;
        a {
            color: #2d63e0;
        }
    }
}
</style>
